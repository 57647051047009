import { FC, useEffect, useRef, useState } from 'react'

import { captureException } from '@sentry/browser'
import ReactPlayer from 'react-player'

import IFramePlayer from 'src/components/IFramePlayer/IFramePlayer'
import Loading from 'src/components/Library/Loading'
import LoomPlayer from 'src/components/LoomPlayer/LoomPlayer'

interface VideoComponentProps {
  video?: string
  onError: (error: string) => void
  parentClassName?: string
}

const VideoComponent: FC<VideoComponentProps> = ({
  video,
  onError,
  parentClassName,
}) => {
  const player = useRef<ReactPlayer | null>(null)
  const [loading, setLoading] = useState(false)
  const [loomVideo, setLoomVideo] = useState('')
  const [videoUrl, setVideoUrl] = useState('')

  const [iFrameVideo, setIFrameVideo] = useState('')

  useEffect(() => {
    if (player && video) {
      setVideoUrl(video)
      setLoading(false)
    }
  }, [player, video])

  useEffect(() => {
    {
      let loomVideoUrl = video
      if (loomVideoUrl && loomVideoUrl.includes('loom.com')) {
        if (loomVideoUrl.includes('loom.com/embed/')) {
          loomVideoUrl = loomVideoUrl.replace(
            'loom.com/embed/',
            'loom.com/share/',
          )
        }
        if (loomVideoUrl.includes('?')) {
          loomVideoUrl = loomVideoUrl.split('?')[0]
        }
        setLoomVideo(loomVideoUrl)
      }
    }
  }, [video])

  useEffect(() => {
    {
      // Handle IFrame video URLs
      const iFrameVideoUrl = video
      if (
        iFrameVideoUrl &&
        (iFrameVideoUrl.includes('storyxpress.co') ||
          iFrameVideoUrl.includes('.mp4'))
      ) {
        setIFrameVideo(iFrameVideoUrl)
      }
    }
  }, [video])

  useEffect(() => {
    {
      // Handle Steam MS video URLs. Can use iFrame video player
      const iFrameVideoUrl = video
      if (
        iFrameVideoUrl &&
        iFrameVideoUrl.includes('stafflinkcomau') &&
        iFrameVideoUrl.includes('sharepoint')
      ) {
        setIFrameVideo(iFrameVideoUrl.match(/src="([^"]+)"/)?.[1])
      }
    }
  }, [video])

  const handleReactPlayerError = (error: { message: string; data: any }) => {
    captureException(
      new Error(
        `React Video player error: ${error.message}, data: ${error.data}`,
      ),
    )
    onError(error.message)
  }

  return (
    <div className={parentClassName}>
      {(() => {
        if (loading) {
          return (
            <div className="flex h-full w-full">
              <Loading />
            </div>
          )
        } else if (video) {
          if (loomVideo) {
            return <LoomPlayer onError={onError} video={loomVideo} />
          } else if (iFrameVideo) {
            return <IFramePlayer video={iFrameVideo} />
          } else {
            return (
              <div className="player-wrapper">
                <ReactPlayer
                  ref={player}
                  onError={(error) => handleReactPlayerError(error)}
                  data-testid="react-player-video"
                  className="react-player"
                  width="100%"
                  height="100%"
                  url={videoUrl}
                  controls={true}
                  progressInterval={15000}
                  config={{
                    file: {
                      attributes: {
                        crossOrigin: 'true',
                      },
                    },
                  }}
                />
              </div>
            )
          }
        }
      })()}
    </div>
  )
}

export default VideoComponent
