import { useState, useEffect, FC } from 'react'

import { type LearnerTrackingStatus } from 'types/graphql'

import { useAuth } from 'src/Providers'

import { SetTaskStatus, TaskType } from '../types'

import ViewQuestion from './Question/ViewQuestion'
import Summary from './Summary/Summary'

interface LearnerQuizProps {
  questions: TaskType['quiz']
  currentQuestion: number
  onSetCurrentQuestion: (nextQuestion: number) => void
  learnerTaskTracking: any
  onUpdateLearnerTaskTracking: any
  setTaskStatus: SetTaskStatus
  taskId: number
  showAnswers?: boolean
}

const LearnerQuiz: FC<LearnerQuizProps> = ({
  questions,
  currentQuestion,
  onSetCurrentQuestion,
  learnerTaskTracking,
  onUpdateLearnerTaskTracking,
  setTaskStatus,
  taskId,
  showAnswers,
}) => {
  const { currentUser } = useAuth()
  const [results, setResults] = useState<any[]>(
    learnerTaskTracking?.progressData?.length === questions.length &&
      learnerTaskTracking?.progressData?.[0] !== ''
      ? learnerTaskTracking?.progressData
      : questions.map((question) => Array(question.choices.length).fill(false)),
  )

  //go through each question
  //go through each choice of every question
  //check if each choice matches the isAnswer
  const markQuiz = (questions, results) => {
    return questions.filter((question, questionIndex) => {
      let correct = true
      question.choices.forEach((choice, choiceIndex) => {
        if (choice.isAnswer !== results[questionIndex][choiceIndex]) {
          correct = false
        }
      })
      return correct
    }).length
  }

  const [correctAnswersCount, setCorrect] = useState<number>(
    markQuiz(questions, results),
  )

  useEffect(() => {}, [results])

  const submitAnswer = (updatedResult) => {
    setResults(updatedResult)
    onSetCurrentQuestion(currentQuestion + 1)

    const validAnswerCount = markQuiz(questions, updatedResult)
    setCorrect(validAnswerCount)
    const score = validAnswerCount / questions.length

    //include save to tracking here
    if (!currentUser.isClientAlias && learnerTaskTracking?.id) {
      onUpdateLearnerTaskTracking({
        variables: {
          id: learnerTaskTracking?.id,
          input: {
            progressData: results,
            status:
              score < 0.75
                ? ('IN_PROGRESS' as LearnerTrackingStatus)
                : ('COMPLETED' as LearnerTrackingStatus),
          },
        },
      })

      setTaskStatus((vals) => {
        return [
          ...vals.filter((taskStatus) => taskStatus.taskId !== taskId),
          {
            taskId: taskId,
            status:
              score < 0.75
                ? ('IN_PROGRESS' as LearnerTrackingStatus)
                : ('COMPLETED' as LearnerTrackingStatus),
          },
        ]
      })
    }
  }

  const goBack = () => {
    onSetCurrentQuestion(currentQuestion - 1)
  }

  return (
    <>
      {questions[currentQuestion] ? (
        <ViewQuestion
          questionIndex={currentQuestion}
          question={questions[currentQuestion]}
          isExpandable={false}
          onSubmit={submitAnswer}
          onGoBack={goBack}
          results={results}
        />
      ) : (
        <Summary
          questions={questions}
          results={results}
          submitAnswer={submitAnswer}
          correct={correctAnswersCount}
          showAnswers={showAnswers}
        />
      )}
    </>
  )
}

export default LearnerQuiz
