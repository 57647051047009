import { useEffect, useRef, useState } from 'react'

import { oembed, validate } from '@loomhq/loom-embed'
import { Player } from 'player.js'

type Props = {
  video: string
  autoplay?: boolean
  onError: (error: string) => void
}

const LoomPlayer = (props: Props) => {
  const { video = '', onError } = props
  const ref = useRef<HTMLDivElement>(null)
  const [videoHTML, setVideoHTML] = useState('')

  useEffect(() => {
    if (!videoHTML || !ref.current) {
      return
    }

    const player = Player(ref?.current?.querySelector('iframe'))

    return () => {
      // HACK: This function gets called in the player.js lib after the player is destroyed
      // So that we don't get errors, we override to a noop function
      player.send = function () {}
      player?.off('ready')
      player?.off('play')
      player?.off('pause')
      player?.off('ended')
      player?.off('seek')
    }
  }, [videoHTML])

  useEffect(() => {
    ;(async () => {
      if (validate.isLoomUrl(video)) {
        const { html } = await oembed(video)
        setVideoHTML(html)
      } else {
        onError('Invalid loom link:' + video)
      }
    })()
  }, [video, onError])

  return videoHTML ? (
    <div
      ref={ref}
      id="player"
      dangerouslySetInnerHTML={{ __html: videoHTML }}
    />
  ) : null
}

export default LoomPlayer
